import React from 'react';
import Fade from 'react-reveal/Fade';

const style = {
    margin: "5%",
    lineHeight: 1.4
};

const Paragraph = (props) => <Fade><p style={ style }>{props.children}</p></Fade>;

export default Paragraph;

import React from 'react';
import LightSpeed from 'react-reveal/LightSpeed';
import Slide from 'react-reveal/Slide';
import Button from '@material-ui/core/Button';

const Header = () => (
    <header>
      <Slide right cascade>
      <div style={{position: 'absolute', top: 40, right: 40 }}>
        <Button
          style={{ color: 'white', backgroundColor: '#F92672' }}
          variant='contained'
          color='primary'
          size='large'
          href='//brother.apota.org'>Brother Portal</Button>
      </div>
        <div style={{ position: 'absolute', top: 100, right: 40 }}>
          <Button
            style={{ color: 'white' }}
            variant='contained'
            color='primary'
            size='large'
          href='//eboard.apota.org'>Officer Portal</Button>
        </div>
    </Slide>
      <LightSpeed left cascade>
        <div className='App-header'>
          <h1>ΑΦΩ</h1>    
          <h2>Theta Alpha</h2>
        </div>
      </LightSpeed>
    </header>
);

export default Header;

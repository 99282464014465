import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyBwbgYOi0eQ92o5GaIbsm0D3-zTSFUF9OI",
    authDomain: "fir-test-4497d.firebaseapp.com",
    databaseURL: "https://fir-test-4497d.firebaseio.com",
    projectId: "fir-test-4497d",
    storageBucket: "fir-test-4497d.appspot.com",
    messagingSenderId: "726177174310",
    appId: "1:726177174310:web:ef28c16713beb69b"
};

firebase.initializeApp(config);
export default firebase;

import React from 'react';
import Paragraph from './Paragraph';

const body = (<div><Paragraph><h2>What is APO?</h2>
                Alpha Phi Omega is an international coed service fraternity founded on the principles of leadership, friendship and service. Since its founding in 1925, more than 350,000 members have joined the organization, with over 17,000 active students today. Of the many student groups at Stevens, Alpha Phi Omega is particularly turning heads both on and off campus. The Stevens Chapter is a very large and growing fraternity, attracting highly motivated students.
                
                Currently, the chapter includes approximately 90 active members. The term “fraternity” can be misleading, since Alpha Phi Omega falls into a category of its own. The coeducational, alcohol-free organization is not considered a “social” fraternity. Our group is devoted to serving the community and developing leadership skills in addition to forming brotherly bonds between members. </Paragraph>
              <Paragraph><h2>Service</h2>
              At the center of our organization is a unique and dynamic service program designed by our members. We participate in a variety of projects in addition to creating our own events. Each member of our chapter is required to complete 22 hours of community service per semester, though many students go well beyond this level. With such a large group of dedicated members, our capabilities are boundless.

                On our campus alone, our presence is strong in every aspect of student life. We regularly host breakfast during midterms, set up for career fairs, hold voter registration drives, and welcome future students. We frequently venture into Hoboken to lead young Boy Scouts, tutor local students, serve dinners at the homeless shelter, and assist at the Boys and Girls Club. Throughout the semester, you'll find us behind the scenes at a variety of Hoboken events, from the Arts and Music Festival to the Taste of Hoboken. Beyond Hoboken, we staff charity walks, clean up beaches, work with Habitat for Humanity, visit nursing homes, and much more. We are always looking to expand our program and welcome any opportunities to lend a helping hand. </Paragraph>
              <Paragraph><h2>Fellowship</h2>
                While service is our main purpose, friendship is an equally important part of our organization. Our strong brotherly bonds distinguish Alpha Phi Omega from ordinary service clubs. Alongside our service program is a fellowship program, which includes a variety of events to strengthen these bonds. Some of our favorite events are Games Night, Duckbills Dinners (in honor of our school currency), the annual APO Ball, and spontaneous get-togethers that happen every day.
                
                Our large chapter is broken into families named after our rechartering members. Each family has its own personality and traditions that make it feel like a home away from home. Above all, we try to create a welcoming environment for all of our members, and we invite all interested students to pledge our fraternity. </Paragraph>
              <Paragraph><h2>Leadership</h2>
                Our fraternity is designed to build leadership qualities in our members. From creating our own programs, we learn to network with a variety of organizations and communicate with different kinds of people. We also lead each other, mentoring new members on an individual basis as Big Brothers, or “Bigs”. Even the new members are asked to challenge their leadership skills. Each new member class must host three events; one service event, one fellowship event, and one fundraising event. In the past, they have taken students on a tour of our college, painted murals at the children's wing of a local hospital, held a social at a senior citizens’ home, and hosted a field day for the children of Hoboken. Not all of our members are natural leaders, but we can develop our skills through our unique APO LEADS program. The national program is a series of five seminars—Launch, Explore, Achieve, Discover and Serve—offered at our national and sectional conferences.</Paragraph>
              <Paragraph><h2>How did Theta Alpha come about?</h2>
                The Stevens Chapter of Alpha Phi Omega, Theta Alpha, first existed in 1949, and throughout the 1950s and 1960s; however its charter was revoked when membership declined. In 1997 the chapter was brought back to life by a group of students looking to start a community service organization on campus. Later that year two of the students, Aileen Tang and Niamh Joyce, flew to Washington state to meet with the Alpha Phi Omega Regional director, David Corning, to help spark interest in APO at Stevens. Returning very enthusiastic about APO, they began to gather interest and form a petitioning group for rechartering. The petitioning group, headed by Aileen Tang, participated in several community service projects, such as working at soup kitchens, working with disadvantaged children, and helping Habitat for Humanity. After three years of undergoing the pledging process, APO was received approval to regain its official charter. Stevens received its charter March 4, 2000 with a membership of 24 brothers, and has been going strong ever since. </Paragraph>
              <Paragraph><h2>Contact</h2>
                To contact the Executive Committee of the Theta Alpha chapter, send an email to <a href="mailto:apo@stevens.edu">apo@stevens.edu</a>.</Paragraph></div>);

const Body = () => body;

export default Body;

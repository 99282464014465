import React, { Component } from 'react';

import './App.css';
import Body from './Body';
import Paragraph from './Paragraph';
import Calendar from './Calendar';
import Header from './Header';
import firebase from './firebase.js';


class App extends Component {
    constructor() {
        super();
        this.state = {
            currentItem: '',
            username: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleSubmit(e) {
        e.preventDefault();
        const itemsRef = firebase.database().ref('items');
        const item = {
            title: this.state.currentItem,
            user: this.state.username
        };
        itemsRef.push(item);
        this.setState({
            currentItem: '',
            username: ''
        });
    }        
    render() {
        return (
	    <div className='App'>
              <Header/>
              <Body/>
              <Paragraph><Calendar/></Paragraph>
            </div>
        );
    }
}

export default App;

